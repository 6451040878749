import React from "react";

type ImageSkeletonProps = {
  width?: number | string;
  height?: number | string;
  className?: string;
  radius?: string;
  color?: string;
  text?: boolean;
};

// type ImageLoaderProps = {};

const ImageSkeleton: React.FC<ImageSkeletonProps> = ({
  width,
  height,
  className = "",
  radius = "rounded-md",
  color,
  text = false,
}) => {
  return (
    <div className={`animate-pulse`} style={{ width: width, height: height }}>
      <div
        style={{ width: width, height: height }}
        className={` ${
          color ? `bg-[${color}]` : "bg-gray-300"
        } items-center justify-center flex ${className} ${radius} `}
      >
        <svg
          className="w-10 h-10 text-gray-200"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
        </svg>
      </div>
      {text && (
        <div className="pr-4">
          <div className="h-2.5 bg-gray-200 rounded-full w-48 my-2"></div>
          <div className="h-2 bg-gray-200 rounded-full max-w-[480px] mb-1"></div>
          <div className="h-2 bg-gray-200 rounded-full mb-1"></div>
          <div className="h-2 bg-gray-200 rounded-full max-w-[440px] mb-1"></div>{" "}
        </div>
      )}
    </div>
  );
};

// export const ImageLoader = ({}) => {
//   return (
//     <div className="h-full w-full inset-0 flex items-center justify-center">
//       <svg
//         fill="#000000"
//         viewBox="0 -0.5 25 25"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
//         <g
//           id="SVGRepo_tracerCarrier"
//           stroke-linecap="round"
//           stroke-linejoin="round"
//         ></g>
//         <g id="SVGRepo_iconCarrier">
//           <path d="m4.818 6.664s0 0-.001 0c-1.02 0-1.847-.827-1.847-1.847s.827-1.847 1.847-1.847 1.847.827 1.847 1.847c0 .51-.207.972-.541 1.306-.324.334-.776.541-1.277.541-.01 0-.019 0-.029 0zm-2.97 7.182s0 0-.001 0c-1.02 0-1.847-.827-1.847-1.847s.827-1.847 1.847-1.847 1.847.827 1.847 1.847c0 .51-.207.972-.541 1.306-.324.334-.776.541-1.278.541-.01 0-.021 0-.031 0h.002zm10.152-10.154s0 0-.001 0c-1.02 0-1.847-.827-1.847-1.847s.827-1.847 1.847-1.847 1.847.827 1.847 1.847c0 .51-.207.972-.541 1.306-.324.334-.776.541-1.277.541-.01 0-.019 0-.029 0zm-7.182 17.337s0 0-.001 0c-1.02 0-1.847-.827-1.847-1.847s.827-1.847 1.847-1.847 1.847.827 1.847 1.847c0 .51-.207.972-.541 1.306-.323.334-.775.541-1.276.541-.01 0-.021 0-.031 0zm14.364-13.904c-1.275 0-2.308-1.033-2.308-2.308s1.033-2.308 2.308-2.308c1.275 0 2.308 1.033 2.308 2.308s-1.033 2.308-2.308 2.308zm-7.182 16.875s0 0-.001 0c-1.02 0-1.847-.827-1.847-1.847s.827-1.847 1.847-1.847 1.847.827 1.847 1.847c0 .51-.207.972-.541 1.306-.324.334-.776.541-1.277.541-.01 0-.019 0-.029 0zm10.154-9.231c-.014 0-.031 0-.048 0-.75 0-1.428-.309-1.913-.807l-.001-.001c-.499-.503-.808-1.196-.808-1.961s.308-1.458.808-1.962c.486-.499 1.164-.808 1.914-.808h.05-.003.048c.75 0 1.427.309 1.913.807l.001.001c.499.503.808 1.196.808 1.961s-.308 1.458-.808 1.962c-.486.499-1.164.809-1.915.809-.016 0-.033 0-.049 0h.002zm-2.971 7.643c-.015 0-.032 0-.05 0-.878 0-1.671-.365-2.236-.951l-.001-.001c-.584-.584-.945-1.391-.945-2.283s.361-1.698.945-2.283c.567-.583 1.358-.945 2.234-.945h.054-.003.042c.877 0 1.67.362 2.237.944l.001.001c.588.582.952 1.39.952 2.283s-.364 1.7-.952 2.282c-.567.588-1.361.953-2.24.953-.014 0-.027 0-.04 0z"></path>
//         </g>
//       </svg>
//     </div>
//   );
// };

export default ImageSkeleton;
