const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

// Dashboard Urls Functions
const storeDashboardUrl = (storeId: string | number) =>
  `${baseUrl}/store/dashboard/${storeId}/`;
const latestProductsUrl = (storeId: string | number) =>
  `${baseUrl}/store/dashboard/${storeId}/`;
const advertsUrl = (storeId: string | number) =>
  `${baseUrl}/store/dashboard/store/adverts/${storeId}/`;
const acceptOrderUrl = (orderId: string | number) =>
  `${baseUrl}/order/dashboard/orders/accept/${orderId}/`;
const shipOrderUrl = (orderId: string | number) =>
  `${baseUrl}/order/dashboard/orders/ship/${orderId}/`;
const productImageDeleteUrl = (imageId: string | number) =>
  `${baseUrl}/store/product/image/delete/${imageId}`;
const productTitleUrl = (productId: string | number | null) =>
  `${baseUrl}/store/dashboard/products/product-media/${productId}/`;
const reviewsUrl = (storeId: string | number) =>
  `${baseUrl}/store/dashboard/products/reviews/${storeId}/`;
const salesUrl = (storeId: string | number) =>
  `${baseUrl}/order/dashboard/store/sales/${storeId}/`;
const incomeUrl = (storeId: string | number) =>
  `${baseUrl}/order/dashboard/store/income-statement/${storeId}/`;
const revenueUrl = (storeId: string | number) =>
  `${baseUrl}/order/dashboard/store/revenue/${storeId}/`;
const deactivateStoreUrl = (storeId: string | number) =>
  `${baseUrl}/store/dashboard/store/deactivate/${storeId}/`;
const companyDeleteUrl = (productId: string | number | null | undefined) =>
  `${baseUrl}/store/dashboard/products/${productId}/delete/`;
const paymentUrl = (advertID: string | number) =>
  `${baseUrl}/order/dashboard/create-payment-intent/${advertID}/`;
const advertPeriodUrl = (storeId: string | number) =>
  `${baseUrl}/store/dashboard/store/adverts/period/list/${storeId}/`;
const createProductAdvertUrl = (storeId: string | number) =>
  `${baseUrl}/store/dashboard/store/adverts/create/${storeId}/`;
const editProductUrl = (productKwargsId: string | number | null | undefined) =>
  `${baseUrl}/store/dashboard/products/${productKwargsId}/update/`;
const addMainImageUrl = (productId: string | number | null) =>
  `${baseUrl}/store/dashboard/products/image/update/${productId}/`;
const productsCountURL = (userId: string | number) =>
  `${baseUrl}/store/dashboard/store/products/count/${userId}/`;
const addMainVideoUrl = (productId: string | number | null) =>
  `${baseUrl}/store/dashboard/products/video/update/${productId}/`;
const storePayoutUrl = (storeId: string | number) =>
  `${baseUrl}/store/dashboard/store/payout/${storeId}/`;
const editStoreUrl = (storeId: string | number) =>
  `${baseUrl}/store/dashboard/store/update/${storeId}/`;
const uploadDisplayImage = (storeId: string | number) =>
  `${baseUrl}/store/dashboard/store/display-image/update/${storeId}/`;
const uploadIdentificationImage = (storeId: string | number) =>
  `${baseUrl}/store/dashboard/store/identification-image/update/${storeId}/`;

// Frontend Urls Functions
const productDetailUrl = (productName: string | string[] | number | null) =>
  `${baseUrl}/store/product/${productName}/`;
const userProfileUrl = (profileId: string | string[] | number | null) =>
  `${baseUrl}/accounts/user-profile/${profileId}/`;
const orderDetailUrl = (orderId: string | string[] | number | null) =>
  `${baseUrl}/order/orders/${orderId}/`;
const productReviewsListUrl = (producId: string | string[] | number | null) =>
  `${baseUrl}/store/product-review-list-api-view/${producId}/`;
const defaultShippingAddressUrl = (userId: string | string[] | number | null) =>
  `${baseUrl}/accounts/shipping-address/${userId}/`;
const storeDetailUrl = (storeName: string | string[] | number | null) =>
  `${baseUrl}/store/products/detail/${storeName}/`;
const modifyWishListUrl = (productId: string | string[] | number) =>
  `${baseUrl}/store/products/wish-list/${productId}/`;
const aboutUs = (aboutUsId: number | null) =>
  `${baseUrl}/public/about-us/${aboutUsId}/`;
const privacyPolicy = (privacyPolicyId: number | null) =>
  `${baseUrl}/public/privacy-policy/${privacyPolicyId}/`;
const termsCondition = (termsConditionId: number | null) =>
  `${baseUrl}/public/terms-condition/${termsConditionId}/`;
const paymentStatusUrl = (paymentId: number | null) =>
  `${baseUrl}/order/payment-status/${paymentId}/`;
const payOnDeliveryUrl = (paymentId: number | null) =>
  `${baseUrl}/order/payment-pay-on-delivery/${paymentId}/`;
const initiatePhonePePayment = (paymentId: number | null) =>
  `${baseUrl}/order/initiate-payment/${paymentId}/`;
const profileUploadPictureUrl = (userId: number | null) =>
  `${baseUrl}/accounts/profile/${userId}/upload-picture/`;
const userCancelOrderUrl = (orderItemId: string | number) =>
  `${baseUrl}/order/user-cancel-order/${orderItemId}/`;
const userRefundOrderUrl = (orderItemId: string | number) =>
  `${baseUrl}/order/user-refund-order/${orderItemId}/`;

export const URLS = {
  // Auth Urls
  loginUrl: `${baseUrl}/accounts/api/token/`,
  updateTokenUrl: `${baseUrl}/auth/token/refresh/`,
  registerUrl: `${baseUrl}/accounts/register/`,
  forgotPasswordUrl: `${baseUrl}/accounts/forgot-password/`,
  enterNewPasswordUrl: `${baseUrl}/accounts/forgot-password/enter-new/`,

  // Dashboard Urls
  storeDashboardUrl,
  latestProductsUrl,
  advertsUrl,
  cancelledOrders: `${baseUrl}/order/dashboard/orders/cancelled/`,
  storeOrderUrl: `${baseUrl}/order/dashboard/orders/`,
  rejectOrderUrl: `${baseUrl}/order/dashboard/orders/cancel/`,
  storePendingOrdersUrl: `${baseUrl}/order/dashboard/orders/pending/`,
  acceptOrderUrl,
  shipOrderUrl,
  productImageDeleteUrl,
  productTitleUrl,
  allHomeProductsUrl: `${baseUrl}/store/dashboard/products/all/`,
  activeProductsUrl: `${baseUrl}/store/dashboard/products/active/`,
  draftProductsUrl: `${baseUrl}/store/dashboard/products/draft/`,
  inStockProductsUrl: `${baseUrl}/store/dashboard/products/instock/`,
  outOfStockProductsUrl: `${baseUrl}/store/dashboard/products/out-of-stock/`,
  conversionRatesProductsUrl: `${baseUrl}/store/dashboard/products/conversion-rates/`,
  reviewsUrl,
  salesUrl,
  revenueUrl,
  deactivateStoreUrl,
  visitorsUrl: `${baseUrl}/store/dashboard/visitors/`,
  companyDeleteUrl,
  paymentUrl,
  productsUrl: `${baseUrl}/store/dashboard/products/all/`,
  advertPeriodUrl,
  createProductAdvertUrl,
  allCategoriesUrl: `${baseUrl}/store/all-category/list/`,
  allSubCategoriesUrl: `${baseUrl}/store/dashboard/sub-categories/`,
  allConditionsUrl: `${baseUrl}/store/dashboard/product-conditions/`,
  addProductUrl: `${baseUrl}/store/dashboard/products/create/`,
  editProductUrl,
  addMainImageUrl,
  photosImageUploadUrl: `${baseUrl}/store/dashboard/products/photos/add/`,
  productsCountURL,
  addMainVideoUrl,
  storePayoutUrl,
  countriesUrl: `${baseUrl}/utility/country-list-api-view/`,
  banksUrl: `${baseUrl}/utility/bank-api-view/`,
  statesUrl: `${baseUrl}/utility/state-api-view/`,
  citiesUrl: `${baseUrl}/utility/city-api-view/`,
  editStoreUrl,
  uploadDisplayImage,
  uploadIdentificationImage,
  incomeUrl,
  notificationsUrl: `${baseUrl}/notification/all/`,

  // FRONTEND URLS
  productDetailUrl,
  userProfileUrl,
  collectionsUrl: `${baseUrl}/store/home/products/all/`,
  featuredProductsUrl: `${baseUrl}/store/home/products/featured-products/`,
  limitedTimeOfferUrl: `${baseUrl}/store/home/products/limited-offer/`,
  newArrivalsUrl: `${baseUrl}/store/home/products/new-arrivals/`,
  trendingUrl: `${baseUrl}/store/home/products/trending/`,
  shopCategoriesUrl: `${baseUrl}/store/home/category/sub-categories`,
  vendorsCollectionsUrl: `${baseUrl}/store/home/store/top-vendors/`,
  categoriesUrl: `${baseUrl}/store/category/list/`,
  searchProducts: `${baseUrl}/store/products/search/`,
  activateUrl: `${baseUrl}/accounts/activate-account/`,
  allProductsUrl: `${baseUrl}/store/all-products/`,
  wishListUrl: `${baseUrl}/accounts/wishlist/`,
  ordersListUrl: `${baseUrl}/order/orders/`,
  orderDetailUrl,
  topSellersUrl: `${baseUrl}/store/top-sellers/`,
  defaultShippingAddressUrl,
  productReviewsListUrl,
  hotOffersUrl: `${baseUrl}/store/hot-offers/`,
  storeDetailUrl,
  modifyWishListUrl,
  storeLocationsUrl: `${baseUrl}/store/location/list/`,
  storeSlotsUrl: `${baseUrl}/store/slot/list/`,
  storeCreate: `${baseUrl}/store/stores/create/`,
  createChat: `${baseUrl}/chat/message/merchant/`,
  aboutUs,
  privacyPolicy,
  termsCondition,
  contactUs: `${baseUrl}/public/contact-us/`,
  userCancelOrderUrl, 
  userRefundOrderUrl, 
  
  // Payment Urls
  createPaymentUrl: `${baseUrl}/order/create-payment/`,
  paymentStatusUrl,
  payOnDeliveryUrl,
  initiatePhonePePayment,
  profileUploadPictureUrl,

};
