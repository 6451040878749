import { NextPage } from "next";
import { useContext, useEffect, useRef } from "react";
import Accordian from "./Utils/Accordian";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faCartPlus,
  faEye,
  faGear,
  faSquare,
  faStar,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import Link from "next/link";
import { NavBarContext } from "@/pages/_app";
import AuthContext from "@/context/AuthContext";
import { useRouter } from "next/router";
import Sidebar, { SidebarItem } from "./Sidebar";

const Navigation: NextPage = ({}) => {
  const router = useRouter();
  const NavigationItems = [
    {
      label: "Dashboard",
      url: "/dashboard",
      icon: <FontAwesomeIcon fontSize={13} className="" icon={faSquare} />,
    },
    {
      label: "Products",
      url: "/dashboard/products",
      icon: <FontAwesomeIcon fontSize={13} className="" icon={faBagShopping} />,
      children: [
        {
          label: "All Products",
          url: "/dashboard/products/",
        },
        {
          label: "Add Product",
          url: "/dashboard/products/add/",
        },
        {
          label: "Conversion Rate(s)",
          url: "/dashboard/products/conversion-rates/",
        },
      ],
    },
    {
      label: "Orders",
      url: "/dashboard/orders",
      icon: <FontAwesomeIcon fontSize={13} className="" icon={faBagShopping} />,
      children: [
        {
          label: "All Orders",
          url: "/dashboard/orders",
        },
        {
          label: "Pending Orders",
          url: "/dashboard/orders/pending",
        },
        {
          label: "Cancelled Orders",
          url: "/dashboard/orders/cancelled",
        },
      ],
    },
    {
      label: "Sales",
      url: "/dashboard/sales",
      icon: <FontAwesomeIcon fontSize={13} className="" icon={faCartPlus} />,
      children: [
        {
          label: "Sales",
          url: "/dashboard/sales",
        },
        {
          label: "Revenue",
          url: "/dashboard/sales/revenues",
        },
        {
          label: "Income",
          url: "/dashboard/sales/income-statement",
        },
      ],
    },
    {
      label: "Reviews",
      url: "/dashboard/products/reviews",
      icon: <FontAwesomeIcon fontSize={13} className="" icon={faStar} />,
    },
    {
      label: "Messenger",
      url: "/chat",
      icon: <FontAwesomeIcon fontSize={13} className="" icon={faMessage} />,
    },
    {
      label: "Visitors",
      url: "/dashboard/visitors",
      icon: <FontAwesomeIcon fontSize={13} className="" icon={faEye} />,
    },
    {
      label: "Marketing",
      url: "/dashboard/marketing",
      icon: <FontAwesomeIcon fontSize={13} className="" icon={faCartPlus} />,
      children: [
        {
          label: "Create Advert",
          url: "/dashboard/marketing/ads/create",
          icon: <FontAwesomeIcon fontSize={13} className="" icon={faStar} />,
        },
        {
          label: "Sponsored Products",
          url: "/dashboard/marketing/ads",
          icon: <FontAwesomeIcon fontSize={13} className="" icon={faStar} />,
        },
      ],
    },
    {
      label: "Settings",
      url: "/dashboard/settings",
      icon: <FontAwesomeIcon fontSize={13} className="" icon={faGear} />,
      children: [
        {
          label: (
            <Link
              href={"/dashboard/settings/store-update"}
              children="Store Settings"
            />
          ),
          url: "/dashboard/settings/store-update",
        },
        {
          label: "Payment Details",
          url: "/dashboard/settings/payment-details",
        },
        {
          label: "Deactivate Store",
          url: "/dashboard/settings/deactivate-store",
        },
      ],
    },
  ];

  const { profile } = useContext(AuthContext);
  const navigationRef = useRef<HTMLDivElement>(null);
  const [isNavbarActive] = useContext(NavBarContext);
  useEffect(() => {
    const NavigationElement = navigationRef.current?.classList;
    if (NavigationElement?.contains("slide-in")) {
      NavigationElement.remove("slide-in");
      NavigationElement?.add("hidden");
      return;
    }
    NavigationElement?.add("slide-in");
    NavigationElement?.remove("hidden");
  }, [isNavbarActive]);

  return (
    <div className=" transition-all">
      <Sidebar title={profile?.store?.name}>
        <Accordian>
          {NavigationItems.map((navItem, index) => (
            <SidebarItem
              icon={navItem.icon}
              key={index}
              text={navItem.label}
              children={navItem.children}
              url={navItem.url}
              active={router.pathname === navItem.url}
            />
          ))}
        </Accordian>
      </Sidebar>
    </div>
  );
};

export default Navigation;
