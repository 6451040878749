import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useRouter } from "next/router";
import Cookies from 'js-cookie';
import { URLS } from "@/utils/urls";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(null);
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showLoginError, setShowLoginError] = useState(false)
  const router = useRouter();

  useEffect(() => {
    // This will help me to check if localStorage is available (client-side)
    if (typeof window !== "undefined") {
      const storedAuthTokens = localStorage.getItem("authTokens");
      if (storedAuthTokens) {
        setAuthTokens(JSON.parse(storedAuthTokens));
        setUser(jwt_decode(storedAuthTokens));
        setProfile(JSON.parse(localStorage.getItem("userDetails")));
      }
      setLoading(false);
    }
  }, []);


  const loginUser = async (e) => {
    const loginUrl = URLS?.loginUrl;
    e.preventDefault();
    let response = await fetch(loginUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: e.target.email.value,
        password: e.target.password.value,
      }),
    });
    let data = await response.json();

    if (response.status === 200) {
      setAuthTokens(data);
      setUser({ ...jwt_decode(data.access), store: data.store });
      setProfile({ ...jwt_decode(data.access), store: data.store });
      localStorage.setItem("authTokens", JSON.stringify(data));
      localStorage.setItem("userDetails", JSON.stringify(data));
      Cookies.set('authTokens', data.access, { expires: 7 }); // Set cookie for 7 days
      router.push("/");
      window.location.reload();
    } else {
      // console.log(data)
      // alert("Something went wrong!");
      return { data: null, error: data.detail || "Login Failed" };
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    localStorage.removeItem("userDetails");
    Cookies.remove('authTokens'); // Remove the cookie on logout
    setProfile(null);
    router.push("/account/login");
  };

  const updateUserDetails = async ({ email, first_name, last_name, store }) => {
    const updatedProfile = {
      ...profile,
      email,
      first_name,
      last_name,
      store,
    };
  
    setProfile(updatedProfile);
  
    if (authTokens) {
      localStorage.setItem('userDetails', JSON.stringify(updatedProfile));
    }
  };
  
  const updateToken = async () => {
    const updateTokenUrl = URLS?.updateTokenUrl;

    if (authTokens) {
      let response = await fetch(updateTokenUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refresh: authTokens.refresh }),
      });

      let data = await response.json();

      if (response.status === 200) {
        setAuthTokens(data);
        setUser({ ...jwt_decode(data.access), store: data.store });
        localStorage.setItem("authTokens", JSON.stringify(data));
      } else {
        logoutUser();
      }
    }

    if (loading) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authTokens) {
      let fourMinutes = 1000 * 60 * 2880; //Shoueld be 4 minutes, but now 48hours - 2880
      let interval = setInterval(() => {
        updateToken();
      }, fourMinutes);
      return () => clearInterval(interval);
    }
  }, [authTokens]);

  const contextData = {
    user: user,
    authTokens: authTokens,
    loginUser: loginUser,
    logoutUser: logoutUser,
    profile: profile,
    updateUserDetails: updateUserDetails,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
