import NavBar from "@/components/Dashboard/NavBar";
import Navigation from "@/components/Dashboard/Navigation";
import { AuthProvider } from "@/context/AuthContext";
import "@/styles/globals.css";
import "@/styles/font-styles.css";
import "@/styles/scrollbar.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, createContext, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomeNavigation from "@/components/Frontend/Navigations/HomeNavigation";
import { CartProvider } from "@/context/CartContext";
import Footer from "@/components/Frontend/Pages/Home/Footer";
import ErrorBoundary from "./ErrorBoundary";
import { Toaster } from 'react-hot-toast';

export const NavBarContext = createContext<
  [boolean, Dispatch<SetStateAction<boolean>> | null]
>([false, null]);

export default function App({ Component, pageProps }: AppProps) {
  const [isNavbarActive, setIsNavbarActive] = useState(true);
  const router = useRouter();
  const isDashboard = router.pathname.includes("/dashboard");
  const isChat = router.pathname.includes("/chat");

  return (
    <ErrorBoundary>
      <AuthProvider>
        <CartProvider>
          <NavBarContext.Provider value={[isNavbarActive, setIsNavbarActive]}>
            <ToastContainer position="bottom-right" />
            <Toaster position="top-right" />
            {isDashboard ? (
              <div className="flex w-full">
                <div className=" w-max sm:sticky left-0">
                  <Navigation />
                </div>

                <div className="w-full relative left-0">
                  <div className="sticky top-0 w-full">
                    <NavBar />
                  </div>
                  <div className="px-[40px] sm:px-[50px] w-full overflow-y-auto py-[20px] bg-[#f4f6f9]">
                    <div className="h-[80vh] left-0 sm:block sm:sticky overflow-y-auto no-scrollbar">
                      <Component {...pageProps} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="relative">
                {!isChat && <HomeNavigation />}
                <div className="p-0 m-0">
                  <Component {...pageProps} />
                </div>
                {!isChat && <Footer />}
              </div>
            )}
          </NavBarContext.Provider>
        </CartProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}
