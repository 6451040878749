import { SubCategory } from "@/interfaces/account/Home/SubCategory";
import { NextPage } from "next";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { URLS } from "@/utils/urls";
import LoadingDataSpinner from "../../LoadingDataSpinner";

interface Props {}

const ShopCategory: NextPage<Props> = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [shopCategories, setShopCategories] = useState<SubCategory[] | null>(
    null
  );

  useEffect(() => {
    fetchShopCategories();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchShopCategories = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(URLS?.shopCategoriesUrl, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setShopCategories(data);
      } else {
        console.error("Failed to fetch states");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div id="shop-category" className="px-[98px] sm:items-center sm:px-[14px] md:px-[25px] flex flex-col gap-[30px] ">
      <h4 className="text-[24px] font-montserrat font-[600] ">Shop Category</h4>
      <div className="flex overflow-scroll sm:overflow-auto no-scrollbar justify-between sm:grid sm:grid-cols-2 md:grid md:grid-cols-3 lg:grid-cols-4">
        {shopCategories && !isLoading ? (
          shopCategories.map((shopCategory, index) => (
            <div className="p-2">
              <div className="bg-[#F5F5F5] cursor-pointer overflow-hidden flex items-center justify-center rounded-[10px] min-h-[143px] min-w-[161px] max-h-[143px] max-w-[161px] border border-[#FFCED333] ">
                <Link
                  href={`/products/category/${shopCategory.slug}`}
                  key={index}
                  className=" m-3 w-[161px] flex flex-col items-center gap-[20px] "
                >
                  <div className=" max-h-[79px] max-w-[130px] overflow-hidden flex justify-center rounded-[10px] items-center">
                    <Image
                      width={130}
                      height={79}
                      src={shopCategory.display_image}
                      alt={shopCategory.name}
                      className=" ease-in duration-150 "
                    />
                  </div>
                  <h4 className=" font-[500] w-[150px]  text-center cursor-pointer font-poppins text-[14px] text-grey-500 truncate ">
                    {shopCategory.name}
                  </h4>
                </Link>
              </div>
            </div>
          ))
        ) : (
          <div className="flex justify-center w-full mt-4">
            <LoadingDataSpinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ShopCategory;
